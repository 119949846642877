import React, { PropsWithChildren, ReactElement } from 'react'
import { ITrainersArray, MeetOurCoachesProps } from './MetOurCoachesInterfaces'
import styles from './MeetOurCoaches.module.scss'
import RightAligned from './RightAligned'
import LeftAligned from './LeftAligned'
import useWindowWidth, { heroDesktopMinWidth } from '../../../hooks/useWindowWidth'

const MeetOurCoaches = (props: PropsWithChildren<MeetOurCoachesProps>): ReactElement => {

    const { coachesArray, coachesTitle } = props

    const isDesktop = useWindowWidth(heroDesktopMinWidth)

    return (
        <div className={`mainBox ${styles.coachesWrapper}`}>
            <h2 className={styles.title}>{typeof coachesTitle != 'string' ? coachesTitle[0].text : coachesTitle}</h2>
            {
                coachesArray.map((coach: ITrainersArray, index) => (
                    <div className={styles.coach} key={index}>
                        {
                            isDesktop ?
                            (index % 2 === 0 || index === 0) ? <LeftAligned coach={coach}/> : <RightAligned coach={coach}/> 
                            : 
                            <LeftAligned coach={coach}/>
                        }
                    </div>
                ))
            }
        </div>
    )
}

export default MeetOurCoaches
