import React, { PropsWithChildren, ReactElement } from 'react'
import { IScheduleItems, IScheduleSlice, SchedulerDayCardProps } from './SchedulerDayCardInterfaces'
import styles from './SchedulerDayCard.module.scss'
import Card from './Card'

const SchedulerDayCard = (props: PropsWithChildren<SchedulerDayCardProps>): ReactElement => {
    const { schedules } = props
    return (
        <div className={`mainBox ${styles.SchedulerDayWrapper}`}>
            <h2 className={styles.schedulerDayTitle}>Christi's Schedule</h2>
            {
                schedules.map((schedule: IScheduleSlice, index) => {
                    if (schedule.slice_type === 'schedule')
                        return (
                            <div className={styles.dayWrapper} key={index}>
                                {schedule.primary.schedule_title.length > 0 && <h3 className={styles.dayTitle}>{schedule.primary.schedule_title[0].text}</h3>}
                                <div className={styles.cardsContainer}>
                                    {
                                        schedule.items.map((item: IScheduleItems, index) => (
                                            <Card classItem={item} key={`${index}${item.class[0].text}`} />
                                        ))
                                    }
                                </div>
                            </div>
                        )
                })
            }

        </div>
    )
}

export default SchedulerDayCard
