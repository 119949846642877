import React, { PropsWithChildren, ReactElement } from 'react'
import { IObjectText, TrainersModulesProps } from '../MetOurCoachesInterfaces'
import styles from '.././RightAndLeft.module.scss'


const RightAligned = (props: PropsWithChildren<TrainersModulesProps>): ReactElement => {
    const { coach } = props
    return (
        <>
            <div className={styles.coachImage}>
                <img src={coach.trainer_image?.url} />
            </div>
            <div className={styles.coachText}>
                {coach.trainer_name.length > 0 && <h3 className={styles.coachName}>{coach.trainer_name[0].text}</h3>}
                {coach.trainer_text?.map((objectText: IObjectText, index) => <p key={index} className={styles.coachDescription}>{objectText.text}</p>)}
            </div>
        </>
    )
}

export default RightAligned