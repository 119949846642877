import React, { PropsWithChildren, ReactElement } from 'react'
import styles from './DescriptionModule.module.scss'
import { DescriptionModuleProps, IObjectArray } from './DescriptonModuleInterfaces'



const DescriptionModule = (props: PropsWithChildren<DescriptionModuleProps>): ReactElement => {
    const {description, title} = props
    return (
        <div className={`mainBox ${styles.descriptionWrapper}`}>
            {title && title.length > 0 && <h2 className={styles.title}>{title[0].text}</h2> }
           {description?.map((paragraph : IObjectArray, index )=><p key={index} className={styles.paragraph}>{paragraph.text}</p>)} 
        </div>
    )
}

export default DescriptionModule
