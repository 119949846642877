import React, { FormEvent, PropsWithChildren, ReactElement, useRef, useState } from 'react'
import styles from './KidsProgram.module.scss'
import Helmet from '../../components/Helmet';
import Hero from "../../components/Widgets/HeroMedia";
import DescriptionModule from './DescriptionModule';
import MeetOurCoaches from '../../components/Widgets/MeetOurCoaches';
import { useDependency } from '../../contexts/DependencyContext';
import { ValidationService } from '../../services/ValidationService';
import { FormElement, FormElementType } from '../../components/shared/Form/FormElementsFactory';
import formatters from '../Account_create_page/components/Form/formatters';
import { isEmpty } from 'lodash';
import { messageIcons } from '../../shared/icons';
import DashboardApiService from '../../services/DashboardApiService';
import Form from '../../components/shared/Form';
import Spinner from '../../components/shared/Spinner';
import SubmitPopup from '../../components/shared/Form/SubmitPopup';
import { useRecaptchaScript } from "../../hooks/useRecaptchaScript";
import SchedulerDayCard from '../../components/Widgets/SchedulerDayCard';
import ClassesDescription from './ClassesDescription';
import { graphql } from 'gatsby';
import { getSeoDescription, getSeoTitle } from '../../shared/helpers';


interface KidsProgramProps {
    data: any;
    pageContext: any;
    navigate: any;
}

const KidsProgram = (props: PropsWithChildren<KidsProgramProps>): ReactElement => {
    const {
        data
    } = props.pageContext;

    const locations = props.data.allPrismicLocations.edges.map(edge => edge.node.data.location);
    const programs = data.body;

    //#region Form
    const contactFormElements = [
        {
            type: FormElementType.Select,
            name: "program",
            placeholder: data.form_program_dropdown_label
                ? data.form_program_dropdown_label[0]?.text
                : "Please select a program",
            options: programs.map((x: any) => ({
                label: x.primary.program_name[0].text,
                id: x.primary.program_name[0].text,
                selected: false,
            })),
        },
        {
            type: FormElementType.Text,
            name: "first-name",
            placeholder: "First Name",
            errorMessage: "Invalid name format",
            validations: {
                text: true,
            },
        },
        {
            type: FormElementType.Text,
            name: "last-name",
            placeholder: "Last Name",
            errorMessage: "Invalid name format",
            validations: {
                text: true,
            },
        },
        {
            type: FormElementType.Email,
            name: "email",
            placeholder: data.form_email_input_label
                ? data.form_email_input_label[0]?.text
                : "Email",
            errorMessage: "Invalid email format",
            validations: {
                remote: {
                    val: "validate/email",
                    customError: "We couldn't verify your email address, please check your input"
                }
            },
        },
        {
            type: FormElementType.Tel,
            name: "phone",
            placeholder: data.form_phone_input_label
                ? data.form_phone_input_label[0]?.text
                : "Phone",
            errorMessage: "Invalid phone format",
            validations: {
                tel: true,
                remote: {
                    val: "validate/phone",
                    customError: "We couldn't validate your phone number, please check your input"
                }
            },
            formatter: "phoneNum"
        },
        {
            type: FormElementType.Checkbox,
            name: "consent",
            placeholder: data.form_consent_text
                ? data.form_consent_text[0]?.text
                : "",
        },
    ];

    const [formData, setFormData] = useState<any>({});
    const [formElements, setFormElements] = useState<FormElement[]>(contactFormElements);
    const [recaptchaError, setRecaptchaError] = useState<boolean>(false);
    const [popup, setPopup] = useState<Popup>(null as any);
    const [formErrors, setFormErrors] = useState({});
    const [isLoad, setIsLoad] = useState<boolean>(false)
    const errorsRef = useRef({});

    const validationService = useDependency(ValidationService);

    const handleInputChange = ({ target }: FormEvent): void => {
        const input = target as HTMLInputElement | HTMLTextAreaElement;

        const formElement = contactFormElements.find(fElem => fElem.name === target.name);

        let value = input.value;

        if (formElement?.formatter) {
            const formatterObj = formatters[formElement.formatter];
            const Formatter = formatterObj.class;
            let formatterInstance;

            if (formatterObj.instances[input.name]) {
                formatterInstance = formatterObj.instances[input.name];
            } else {
                formatterObj.instances[input.name] = new Formatter();
                formatterInstance = formatterObj.instances[input.name];
            }

            value = formatterInstance.format(input.value);
        }

        setFormData({
            ...formData,
            [input.name]:
                input.type === FormElementType.Checkbox
                    ? (input as HTMLInputElement).checked
                    : value,
        });
    };

    const handleBlurValidation = async ({ target }: FormEvent): void => {
        const errors = {};

        const { name, type, validations } = formElements.find(
            e => e.name === target.name
        );

        if (validations) {
            for (const key in validations) {
                const validationValue = validations[key];
                const value = formData[name];
                let isValid = false;

                if (validations["optional"] && !value) {
                    break;
                }

                isValid = await validationService.setValidation(
                    value,
                    key,
                    validationValue,
                    name
                );

                if (!isValid) {
                    if (!errors[name]) {
                        errors[name] = [];
                    }

                    let customMsg = "";

                    if (typeof validationValue === "object") {
                        if (validationValue.customError) {
                            customMsg = validationValue.customError;
                        }
                    }

                    const errMsg = customMsg || formElements.find(e => e.name === name)?.errorMessage;

                    errors[name][0] = errMsg;
                    errorsRef.current[name] = errMsg;
                }
            }
        }

        if (!isEmpty(errors)) {
            setFormErrors({
                ...formErrors,
                ...errors,
            });
        } else {
            const _formErrors = { ...formErrors };

            delete _formErrors[name];

            setFormErrors(_formErrors);
        }
    };

    const handleSelectChange = (name: string, value: string): void => {
        setFormData({ ...formData, [name]: value });
    };

    const skipValidation = (name, value) => {
        const arrCopy = [...formElements];
        const optional = arrCopy.find(e => e.name === name);
        optional.validations.skip = value;
        optional.validations.optional = value;
        const errors = { ...formErrors };

        if (value) {
            delete errors[name];
        } else {
            errors[name] = errorsRef.current[name];
        }

        setFormErrors(errors)
        setFormElements(arrCopy);
    }

    const handleFormSubmission = async (e: FormEvent): void => {
        e.persist();
        e.preventDefault();
        setIsLoad(true);

        const form = e.target as HTMLFormElement;
        const recaptchaResponse = grecaptcha.getResponse();
        const selectOptions = formElements.filter(x => x.options)[0];
        const selectedForm = selectOptions.options?.filter(
            option => option.selected
        )[0];
        const errors = {};

        setRecaptchaError(!recaptchaResponse);

        if (!recaptchaResponse) {
            setIsLoad(false);
            return;
        }

        if (formData) {
            for (const formElem of formElements) {
                const { name, type, validations } = formElem;

                if (validations) {
                    if (validations.skip) break;

                    for (const key in validations) {
                        const validationValue = validations[key];
                        const value = formData[name];
                        let isValid = false;

                        isValid = await validationService.setValidation(
                            value,
                            key,
                            validationValue,
                            name
                        );

                        if (!isValid) {
                            if (!errors[name]) {
                                errors[name] = [];
                            }

                            let customMsg = "";

                            if (typeof validationValue === "object") {
                                if (validationValue.customError) {
                                    customMsg = validationValue.customError;
                                }
                            }

                            const errMsg = customMsg || formElements.find(e => e.name === name)?.errorMessage;

                            errors[name][0] = errMsg;
                            errorsRef.current[name] = errMsg;
                        }
                    }
                }
            }

            if (isEmpty(errors)) {
                let programName = formData["program"].toLowerCase();
                const postData = {
                    name: `kids-program-${programName}`,
                    businessUnitCode: locations[0].businessUnitCode,
                    formData: formData,
                    recaptchaToken: recaptchaResponse,
                };

                const response = await DashboardApiService.submitForm(postData);

                if (response.data.success) {
                    setIsLoad(false);
                    props.navigate("thank-you");
                } else {
                    setIsLoad(false);
                    setPopup({
                        icon: messageIcons.error,
                        title: "Something went wrong",
                        message: "Please try again",
                    });
                }
            } else {
                setIsLoad(false);
                setFormErrors(errors);
            }
        }
    };
    useRecaptchaScript();
    //#endregion


    return (
        <>
            <Helmet
                title={getSeoTitle(data, "Kids Program")}
                description={getSeoDescription(data, "Kids Program")}
            />
            <Hero data={data} height={80} />
            {(data.description_text.length > 0 || data.description_title.length > 0)  && <DescriptionModule description={data.description_text} title={data.description_title} />}
            {data.body1.length > 0 && data.body1.find((e : any) => e.slice_type === 'classes') && <ClassesDescription classes={data.body1.find((e : any) => e.slice_type === 'classes')}/>}
            {data.body1.length > 0 && <SchedulerDayCard schedules={data.body1}/>}
            {data.trainers_module.length > 0 && <MeetOurCoaches coachesArray={data.trainers_module} coachesTitle={data.trainers_module_title ? data.trainers_module_title : "Meet our coaches" }/>}
            <div className={`mainBox ${styles.formWrapper}`}>
                <Form
                    name="contact-form"
                    title={data.form_title.length > 0 ? data.form_title[0].text : "Kids program"}
                    formErrors={formErrors}
                    data={formData}
                    elements={formElements}
                    handleBlurValidation={handleBlurValidation}
                    recaptchaError={recaptchaError}
                    {...{
                        handleInputChange,
                        handleSelectChange,
                        handleFormSubmission,
                        skipValidation
                    }}
                />
            </div>
            {popup && (
                <SubmitPopup
                    icon={popup.icon}
                    title={popup.title}
                    message={popup.message}
                    handleClose={() => window.location.reload()}
                />
            )}
            {isLoad && <Spinner></Spinner>}
        </>
    )
}

export const query = graphql`
    query {
        allPrismicLocations {
            edges {
                node {
                    id
                    data {
                        location {
                            businessUnitCode
                            name
                            division
                        }
                    }
                }
            }
        }
    }
`;

export default KidsProgram
