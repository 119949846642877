import React, { PropsWithChildren, ReactElement } from 'react'
import { ClassesDescriptionProps, IClassesItems } from './ClassesDescriptionInterfaces'
import styles from './ClassesDescription.module.scss'
import { IObjectText } from '../../../components/Widgets/MeetOurCoaches/MetOurCoachesInterfaces'

const ClassesDescription = (props: PropsWithChildren<ClassesDescriptionProps>): ReactElement => {

    const { classes } = props

    return (
        <div className={`mainBox ${styles.classesWrapper}`}>
            <h2 className={styles.classesTitle}>{classes.primary.christis_classes[0]?.text ? classes.primary.christis_classes[0]?.text : "Christi's classes"}</h2>
            {
                classes.items.map((item: IClassesItems, index) => (
                    <div className={styles.classWrapper} key={index}>
                        {item.class_title.length > 0 && <h3 className={styles.classTitle}>{item.class_title[0].text}</h3>}
                        {item.class_description.length > 0 &&
                            item.class_description.map((description: IObjectText , index ) => <p key={index}>{description.text}</p>)
                        }
                        {item.class_bold_information.length > 0 && <p className={styles.boldText}>{item.class_bold_information[0].text}</p>}
                    </div>
                )
                )
            }

        </div>
    )
}

export default ClassesDescription
