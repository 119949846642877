import React, { PropsWithChildren, ReactElement } from 'react'
import { CardProps } from '../SchedulerDayCardInterfaces'
import styles from './Card.module.scss'


const Card = (props: PropsWithChildren<CardProps>): ReactElement => {
    const { classItem } = props
    return (
        <div className={styles.cardWrapper}>
            {classItem.hours.length > 0 && <p className={styles.simpleText}>{classItem.hours[0].text}</p>}
            {classItem.class.length > 0 &&<p className={styles.simpleText}>{classItem.class[0].text}</p>}
            {classItem.bold_text.length > 0 &&<p className={styles.boldText}>{classItem.bold_text[0].text}</p>}
        </div>
    )
}

export default Card
